import React from 'react';
import { Button } from 'react-gamepad';
import { History } from "history";
import GamepadWrapper from './GamepadWrapper';

interface ChessPiece {
  icon: string;
  x: number;
  y: number;
}

interface ChessProps {
  history: History;
}

interface ChessState {
  selectX: number;
  selectY: number;
  pieces: ChessPiece[];
  selectedPieceIndex?: number;
}

export default class Chess extends React.Component<ChessProps, ChessState> {
    constructor(props: ChessProps) {
      super(props);

      this.state = {
        selectX: 0,
        selectY: 0,
        pieces: [
          ...[0, 7].map(x => { return { icon: '\u2656', x, y: 0 }}), // Rooks
          ...[1, 6].map(x => { return { icon: '\u2658', x, y: 0 }}), // Knights
          ...[2, 5].map(x => { return { icon: '\u2657', x, y: 0 }}), // Bishops
          { icon: '\u2655', x: 3, y: 0 }, // Queen
          { icon: '\u2654', x: 4, y: 0 }, // King
          ...[0, 1, 2, 3, 4, 5, 6, 7].map(x => { return { icon: '\u2659', x, y: 1 }}), // Pawns

          ...[0, 7].map(x => { return { icon: '\u265C', x, y: 7 }}), // Rooks
          ...[1, 6].map(x => { return { icon: '\u265E', x, y: 7 }}), // Knights
          ...[2, 5].map(x => { return { icon: '\u265D', x, y: 7 }}), // Bishops
          { icon: '\u265B', x: 3, y: 7 }, // Queen
          { icon: '\u265A', x: 4, y: 7 }, // King
          ...[0, 1, 2, 3, 4, 5, 6, 7].map(x => { return { icon: '\u265F', x, y: 6 }}) // Pawns
        ]
      };
    }

    onUp() {
      this.setState({
        selectY: (8 + this.state.selectY - 1) % 8
      });
    }

    onDown() {
      this.setState({
        selectY: (8 + this.state.selectY + 1) % 8
      });
    }

    onLeft() {
      this.setState({
        selectX: (8 + this.state.selectX - 1) % 8
      });
    }

    onRight() {
      this.setState({
        selectX: (8 + this.state.selectX + 1) % 8
      });
    }

    onButtonDown(button: Button) {
      const { selectX, selectY, pieces, selectedPieceIndex } = this.state;

      console.log("button", button);
      if (["Start", "LS", "A"].includes(button)) {
        const hoverPieceIndex = pieces.findIndex(piece => piece.x === selectX && piece.y === selectY);
        
        if (selectedPieceIndex !== undefined) {
          pieces[selectedPieceIndex].x = selectX;
          pieces[selectedPieceIndex].y = selectY;

          const piecesNew = pieces.filter((piece, i) => piece.x !== selectX || piece.y !== selectY || i === selectedPieceIndex);

          this.setState({ pieces: piecesNew, selectedPieceIndex: undefined });
        }
        else if (hoverPieceIndex !== -1) {
          console.log("hoverPieceIndex" , hoverPieceIndex);
          this.setState({ selectedPieceIndex: hoverPieceIndex });
        }
      }
    }

    render() {
        const { selectX, selectY, pieces, selectedPieceIndex } = this.state;
        return <div style={{
            display: "inlineBlock",
            width: "88vh",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: "1vh"
          }}>
          {[0, 1, 2, 3, 4, 5, 6, 7].map(y => 
            <div style={{ height: "11vh" }}>
              {[0, 1, 2, 3, 4, 5, 6, 7].map(x => {
                  const colour = ((x + y) % 2) ? "white" : "red";
                  const pieceIndexInSquare = pieces.findIndex(piece => (piece.x === x && piece.y === y));
                  const pieceInSquare = pieces[pieceIndexInSquare];

                  const pieceSelected = pieceIndexInSquare === selectedPieceIndex;
                  const squareSelected = selectX === x && selectY === y;

                  return <div style={{
                    width: "10vh",
                    height: "10vh",
                    backgroundColor: pieceSelected ? "green" : colour,
                    display: "inline-block",
                    borderWidth: "0.5vh",
                    borderColor: squareSelected ? "black" : colour,
                    borderStyle: "solid",
                    color: "black",
                    fontSize: "8vh",
                    lineHeight: "10vh",
                    verticalAlign: "middle",
                    textAlign: "center"
                  }}>
                    {pieceInSquare?.icon || <span>&nbsp;</span>}
                  </div>
                }
              )}
            </div>
          )}
          <div style={{ fontSize: "2vh" }}>
            <GamepadWrapper
              onButtonDown={this.onButtonDown.bind(this)}
              onDown={this.onDown.bind(this)}
              onUp={this.onUp.bind(this)}
              onLeft={this.onLeft.bind(this)}
              onRight={this.onRight.bind(this)}
              onQuit={() => this.props.history.goBack()}
            />
          </div>
        </div>;
    }
}