import React, { useState } from 'react'
import Gamepad, { Axis, Button } from 'react-gamepad';

interface GamepadWrapperProps {
  onUp?(): void;
  onDown?(): void;
  onLeft?(): void;
  onRight?(): void;
  onButtonDown?(buttonName: Button): void;
  onQuit?(): void;
}

const GamepadWrapper: React.FunctionComponent<GamepadWrapperProps> = ({ onUp, onDown, onLeft, onRight, onButtonDown, onQuit }) => {

  const [confirmingQuit, setConfirmingQuit] = useState<boolean>(false);

  const onAxisChange = (axis: Axis, value: number, oldValue: number) => {
    if (axis === "LeftStickX" && value < -0.5 && oldValue >= -0.5 && onLeft) onLeft();
    if (axis === "LeftStickX" && value > 0.5 && oldValue <= 0.5 && onRight) onRight();
    if (axis === "LeftStickY" && value > 0.5 && oldValue <= 0.5 && onUp) onUp();
    if (axis === "LeftStickY" && value < -0.5 && oldValue >= -0.5 && onDown) onDown();
  }

  const onButtonDownHandler = (buttonName: Button) => {
    if (onQuit) {
      if (buttonName === "B") setConfirmingQuit(true);
      else if (buttonName === "X" && confirmingQuit) onQuit();
      else setConfirmingQuit(false);
    } 

    if (onButtonDown) onButtonDown(buttonName)
  }

  return <>
    <Gamepad
      onAxisChange={onAxisChange}
      onButtonDown={onButtonDownHandler}
    >
      <React.Fragment />
    </Gamepad>
    {onQuit ? <p>Press <strong>{confirmingQuit ? "X" : "B"}</strong> to exit</p> : null}
  </>
}

export default GamepadWrapper;