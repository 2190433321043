import React from 'react';
import { Button } from 'react-gamepad';
import { History } from "history";
import GamepadWrapper from './GamepadWrapper';

const prompts = [
  "What do you eat on a day-to-day basis?",
  "What are you going to do now that lockdown has finished?",
  "How would you sell this pen?"
];

interface TableTopicsProps {
  history: History;
}

interface TableTopicsState {
  text?: string;
}

export default class TableTopics extends React.Component<TableTopicsProps, TableTopicsState> {
    constructor(props: TableTopicsProps) {
      super(props);

      this.state = {
      };
    }

    onButtonDown(button: Button) {
      if (button === "A" || button === "Start") {
        const promptIndex = Math.floor(Math.random() * prompts.length);
        this.setState({ text: prompts[promptIndex] });
      }
    }

    render() {
      const {text} = this.state;

      return <div style={{
          display: "inlineBlock",
          padding: "2vh"
        }}>
        <p style={{ fontSize: "5vh" }}>{text || <span>Press <strong>A</strong> to show a prompt</span>}</p>
        
        <div style={{ fontSize: "2vh" }}>
          <GamepadWrapper
            onQuit={() => this.props.history.goBack()}
            onButtonDown={this.onButtonDown.bind(this)}
          />
        </div>
      </div>;
    }
}