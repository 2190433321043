import React from 'react';
import { Button } from 'react-gamepad';
import GamepadWrapper from './GamepadWrapper';

interface ScreenMenuOption {
  path: string;
  label: string;
}

interface ScreenMenuProps {
  options: ScreenMenuOption[];
  onItemSelected: (option: ScreenMenuOption) => void;
}

interface ScreenMenuState {
  selectedOptionIndex: number;
}

export default class ScreenMenu extends React.Component<ScreenMenuProps, ScreenMenuState> {
  constructor(props: ScreenMenuProps) {
    super(props);

    this.state = {
      selectedOptionIndex: 0
    };
  }

  onButtonDown(key: Button) {
    const { selectedOptionIndex } = this.state;
    const { options, onItemSelected } = this.props;

    console.log("key", key);
    if (["Start", "DPadRight", "A", "LS"].includes(key)) {
      onItemSelected(options[selectedOptionIndex]);
    } else if (key === "X") {
      window.location.reload();
    }
  }

  onUp() {
    const { selectedOptionIndex } = this.state;
    const { options } = this.props;

    this.setState({ selectedOptionIndex: (selectedOptionIndex - 1 + options.length) % options.length });
  }

  onDown() {
    const { selectedOptionIndex } = this.state;
    const { options } = this.props;

    this.setState({ selectedOptionIndex: (selectedOptionIndex + 1 + options.length) % options.length });
  }

  render() {
    const { selectedOptionIndex } = this.state;

    return <div>
      <GamepadWrapper
        onUp={this.onUp.bind(this)}
        onDown={this.onDown.bind(this)}
        onButtonDown={this.onButtonDown.bind(this)}
      />
      <ul>
        {this.props.options.map((option, index) => {
          return <li key={index}
          style={{
            fontWeight: (index === selectedOptionIndex) ? "bold" : "normal",
            fontSize: "4vh",
            textAlign: "center",
            listStyleType: "none",
            margin: 0,
            padding: 8,
            textDecoration: (index === selectedOptionIndex) ? "underline" : "none"
          }}>
            {option.label}
          </li>
        })}
      </ul>
    </div>
  }
}