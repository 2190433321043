import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Chess from "./Chess";
import Home from './Home';
import TableTopics from './TableTopics';

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/chess" render={({ history }) => <Chess history={history}></Chess>}></Route>
        <Route path="/table-topics" render={({ history }) => <TableTopics history={history}></TableTopics>}></Route>
        <Route path="/" render={({ history }) => <Home history={history}></Home>}></Route>
      </Switch>
    </Router>
  );
}

export default App;
