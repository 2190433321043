import React from 'react';
import { History } from "history";
import ScreenMenu from './ScreenMenu';

interface HomeProps {
  history: History;
}

export default class Home extends React.Component<HomeProps> {
  render() {
    return <ScreenMenu
      options={[
        { "label": "Draw", "path": "/draw" },
        { "label": "Chess", "path": "/chess" },
        { "label": "Table Topics", "path": "/table-topics" }
      ]}
      onItemSelected={option => this.props.history.push(option.path)}
    />
  }
}